import { withOverlayControls } from '@eventbrite/eds-structure';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HeaderShape } from '../../../../types/index';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ImageAttribution } from '../../components/ImageAttribution';
import { clickCTASeasonal } from '../../redux/content/actions';
import { HeaderBackgroundContainer } from '../HeaderBackgroundContainer';
import './Header.scss';

interface HeaderProps {
    isLoading: boolean;
    history?: object;
    header: HeaderShape;
    clickCTASeasonal?: Function;
}

export class Header extends Component<HeaderProps> {
    render() {
        const { header, isLoading, clickCTASeasonal } = this.props;

        const feedHeaderClassNames = classNames(['feed-header']);

        let titleComponent = null;

        if (!isLoading) {
            titleComponent = (
                <HeaderTitle
                    header={header}
                    clickCTASeasonal={clickCTASeasonal}
                />
            );
        }

        return (
            <section className={feedHeaderClassNames}>
                <div className="feed-header__bg-box" />
                <HeaderBackgroundContainer
                    type="MainHeaderBackground"
                    isSeasonal={header?.shouldShowCustomContent}
                />

                <div className="feed-header__content">
                    <div>{titleComponent}</div>

                    <ImageAttribution
                        attribution={header?.attribution?.toString()}
                    />
                </div>
            </section>
        );
    }
}

const _mapStateToProps = ({
    header,
    app,
}: {
    header: HeaderShape;
    app: {
        loadingState?: {
            isFeLoading?: boolean;
        };
    };
}) => ({
    header,
    isLoading: app.loadingState && app.loadingState.isFeLoading,
});

const _mapDispatchToProps = {
    clickCTASeasonal,
};

export default withOverlayControls(
    connect(_mapStateToProps, _mapDispatchToProps)(Header),
);
