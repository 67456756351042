import forEach from 'lodash/forEach';
import { HEADER_BACKGROUND_BREAKPOINTS } from './constants';

export const getImageForBreakpoint = ({
    windowWidth,
    imagesSet,
}: {
    windowWidth?: number;
    imagesSet: { [key: string]: any };
}) => {
    let imageUrl = imagesSet.mobile;

    if (windowWidth) {
        forEach(HEADER_BACKGROUND_BREAKPOINTS, (breakpointSize, keyName) => {
            if (windowWidth >= breakpointSize) {
                imageUrl = imagesSet[keyName];
            }
        });
    }

    return imageUrl;
};
