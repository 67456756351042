import { addWindowListener, removeWindowListener } from '@eventbrite/eds-utils';
import classnames from 'classnames';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import './HeaderBackground.scss';
import { getImageForBreakpoint } from './utils';

const MainHeaderBackground = ({
    imageSrc,
    isSeasonal,
}: {
    imageSrc: string;
    isSeasonal: boolean;
}) => {
    let mainBg = null;
    let style = {};
    const [isImageLoaded, setImageLoaded] = useState(false);
    const imageWrapperClasses = classnames('header__main-bg-wrapper', {
        'header__main-bg-wrapper--empty': !isImageLoaded,
        'header__main-bg-wrapper--seasonal': isSeasonal,
    });

    if (imageSrc) {
        mainBg = (
            <img
                className={classnames('header__main-bg', 'eds-hide')}
                src={imageSrc}
                alt="consumer-header-background"
                onLoad={() => setImageLoaded(true)}
            />
        );
    }

    // Once the image is loaded it stays in browser cache,
    // this will not trigger a second request.
    if (isImageLoaded) {
        style = {
            backgroundImage: `url(${imageSrc})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        };
    }

    return (
        <>
            <div className={imageWrapperClasses} style={style}>
                {mainBg}
            </div>
        </>
    );
};

const HEADER_BACKGROUND_TYPES: { [key: string]: any } = {
    MainHeaderBackground,
};

interface HeaderBackgroundProps {
    type: JSX.Element | string;
    preventImageDownloading?: boolean;
    headerImages?: object;
    isSeasonal: boolean;
}

export class HeaderBackground extends Component<HeaderBackgroundProps> {
    state = {
        hasWindow: false,
        imageSrc: '',
    };

    componentDidMount() {
        addWindowListener('resize', this.updateMediaQueryStatus);
        const { headerImages = {} } = this.props;

        this.setState({
            hasWindow: true,
            imageSrc: getImageForBreakpoint({
                windowWidth: window.innerWidth,
                imagesSet: headerImages,
            }),
        });
    }

    //TODO: Refactor to remove component will receive props
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps({ headerImages = {} }) {
        this.setState({
            imageSrc: getImageForBreakpoint({
                windowWidth: window.innerWidth,
                imagesSet: headerImages,
            }),
        });
    }

    componentWillUnmount() {
        if (this.state.hasWindow) {
            removeWindowListener('resize', this.updateMediaQueryStatus);
        }
    }

    updateMediaQueryStatus = () => {
        const { preventImageDownloading, headerImages = {} } = this.props;

        if (!preventImageDownloading && this.state.hasWindow) {
            this.setState({
                imageSrc: getImageForBreakpoint({
                    windowWidth: window.innerWidth,
                    imagesSet: headerImages,
                }),
            });
        }
    };

    render() {
        const { type, isSeasonal } = this.props;
        const { imageSrc } = this.state;
        const Background = HEADER_BACKGROUND_TYPES[type as string];

        return <Background imageSrc={imageSrc} isSeasonal={isSeasonal} />;
    }
}

//TODO: Investigate if this component is still necessary
const _mapStateToProps = ({
    header = {},
}: {
    header: { images?: object };
}) => ({
    headerImages: header.images,
});

export default connect(_mapStateToProps)(HeaderBackground);
