import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky as ArrowRightChunkySVG } from '@eventbrite/eds-iconography';
import { Link } from '@eventbrite/eds-link';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { HeaderShape } from '../../../../types/index';
import './HeaderTitle.scss';

export interface HeaderTitleProps {
    header: HeaderShape;
    clickCTASeasonal?: Function;
    fullbleed?: boolean;
}

const HeaderTitle = ({
    header: {
        shouldShowCustomContent,
        customContentTitle,
        customContentCtaLabel,
        customContentCtaLink,
        customLinkLabel,
        customCombinedHeaderTitleAndCta,
    },
    clickCTASeasonal,
    fullbleed,
}: HeaderTitleProps) => {
    let response = null;

    const _handleCtaOnClick = (event: React.MouseEvent<HTMLLinkElement>) => {
        clickCTASeasonal?.(event);
        if (window && customContentCtaLink) {
            window.location.href = customContentCtaLink;
        }
    };

    if (shouldShowCustomContent) {
        const title = customContentTitle || 'Made for those who do';
        //Note: Use stylesheet to update appearance and don't modify below HTML unless necessary. Translation
        //leverages entire string (including HTML) as key in object lookup
        //  TODO EB-163654 - Incorrect usage of gettext. Please refer to the Jira ticket for more information.
        const titleText = gettext(
            '<div class="feed-header__title-top">%(title)s</div>',
            { title },
        );

        let displayTitle = (
            <>
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: titleText.toString() }}
                />

                <div
                    className="feed-header__title-bottom feed-header__title-bottom--seasonal"
                    data-spec="seasonal-title"
                >
                    {gettext('%(seasonalContent)s', {
                        seasonalContent: customContentCtaLabel,
                    })}
                </div>
            </>
        );

        if (customCombinedHeaderTitleAndCta) {
            displayTitle = (
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: customCombinedHeaderTitleAndCta.toString(),
                    }}
                />
            );
        }

        if (fullbleed) {
            response = (
                <h1 className="feed-fullbleed-header__title">
                    <div className="feed-fullbleed-header__cta-container">
                        <Link
                            aria-label={gettext('Go to %(seasonal)s events', {
                                seasonal: customContentCtaLabel,
                            })}
                            onClick={_handleCtaOnClick}
                            to={customContentCtaLink}
                            data-spec="seasonal-cta"
                        >
                            <div className="feed-fullbleed-header__cta-text eds-text-bm eds-text-weight--heavy">
                                {gettext('%(linkLabel)s', {
                                    linkLabel: customLinkLabel,
                                })}
                            </div>
                        </Link>
                    </div>
                </h1>
            );
        } else {
            response = (
                <h1 className="feed-header__title">
                    {displayTitle}

                    <div className="feed-header__cta-container">
                        <Link
                            aria-label={gettext('Go to %(seasonal)s events', {
                                seasonal: customContentCtaLabel,
                            })}
                            onClick={_handleCtaOnClick}
                            to={customContentCtaLink}
                            data-spec="seasonal-cta"
                        >
                            <div className="feed-header__cta-text eds-text-bm eds-text-weight--heavy eds-show-up-md">
                                {gettext('%(linkLabel)s', {
                                    linkLabel: customLinkLabel,
                                })}
                            </div>
                            <Icon type={<ArrowRightChunkySVG />} size="small" />
                        </Link>
                    </div>
                </h1>
            );
        }
    } else {
        //Note: Use stylesheet to update appearance and don't modify below HTML unless necessary. Translation
        //leverages entire string (including HTML) as key in object lookup
        const title = gettext(
            '<div class="feed-header__title-top">Made for</div><div class="feed-header__title-bottom">those who do</div>',
        );

        response = (
            <h1
                className="feed-header__title"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: title.toString() }}
            />
        );
    }
    return response;
};

export default HeaderTitle;
