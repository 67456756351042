import { IconButton } from '@eventbrite/eds-icon-button';
import { InfoChunky as InfoChunkySvg } from '@eventbrite/eds-iconography';
import classNames from 'classnames';
import React, { useState } from 'react';

const SPACEBAR = 32;
const ENTER = 13;

const ImageAttribution = ({ attribution }: { attribution?: string }) => {
    const [isAttributionExpanded, setAttributionExpanded] = useState(false);

    if (!attribution) {
        return null;
    }

    const classes = classNames('feed-header__attribution eds-text-bs', {
        'feed-header__attribution--expanded': isAttributionExpanded,
    });

    const buttonConfig = {
        onClick: () => setAttributionExpanded(false),
        role: 'button',
        tabIndex: '0',
        'aria-expanded': true,
        title: attribution,
        onKeyDown: ({ keyCode }: { keyCode?: number }) =>
            keyCode === SPACEBAR || keyCode === ENTER
                ? setAttributionExpanded(false)
                : {},
    };

    const buttonVars = isAttributionExpanded ? buttonConfig : {};

    return (
        <div className={classes} {...buttonVars}>
            <span className="feed-header__attribution--icon">
                <IconButton
                    iconType={<InfoChunkySvg />}
                    iconColor="white"
                    title={attribution}
                    onClick={() => setAttributionExpanded(true)}
                />
            </span>
            <span className="feed-header__attribution--text">
                {attribution}
            </span>
        </div>
    );
};

export default ImageAttribution;
